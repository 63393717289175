@import './connect-front/src/index.scss';

body {
	display: initial; // Overwrite 'flex' set in connect-front
}

@media all {
	.page-break {
		height: 1px;
		margin: 2.75rem;
		background: #000;
	}
}
@media print {
	.page-break {
		height: 5.4rem; /* Must be set to the same as the header-height */
		margin: 0px;
		background: none;
		page-break-before: always;
	}
}
